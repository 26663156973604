<template>
  <div
    :class="[
      hideMobile ? 'hidden' : '',
      hideDesktop ? 'lg:hidden' : 'lg:block',
    ]"
  >
    <div
      :class="[
        mobileGap,
        rowGapMobile,
        rowGapDesktop,
        mobileMarginBottom,
        desktopMarginBottom,
        mobileMarginTop,
        desktopMarginTop,
        desktopGap,
        desktopColumns,
        mobileColumns,
        paddingHorizontal,
        desktopPaddingHorizontal,
        paddingVertical,
        desktopPaddingVertical,
        maxWidthMobile,
        maxWidthDesktop,
        mobileSwipeable
          ? 'overflow-x-scroll wrapper flex relative max-w-full lg:grid scrollbar-hide'
          : 'grid justify-center',
      ]"
      :style="`background-color: ${backgroundColorCss}`"
    >
      <component
        :is="item.component"
        v-for="item in content"
        :key="item.id"
        v-editable="item"
        :full-width="layoutDesktop.includes('lg:col-start-1 lg:col-end-13')"
        :class="[layoutMobile, layoutDesktop, ifSwipeableClass]"
        v-bind="{ ...item }"
      />
    </div>
  </div>
</template>

<script>
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'

export default {
  name: 'Grid',
  props: {
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileSwipeable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileGap: {
      type: String,
      required: false,
      default: 'gap-0',
    },
    layoutMobile: {
      type: String,
      required: false,
      default: '',
    },
    layoutDesktop: {
      type: String,
      required: false,
      default: '',
    },
    desktopColumns: {
      type: String,
      required: false,
      default: 'lg:grid-cols-12',
    },
    mobileColumns: {
      type: String,
      required: false,
      default: 'grid-cols-4',
    },
    desktopGap: {
      type: String,
      required: false,
      default: 'lg:gap-0',
    },
    rowGapMobile: {
      type: String,
      required: false,
      default: 'gap-x-0',
    },
    rowGapDesktop: {
      type: String,
      required: false,
      default: 'lg:gap-x-0',
    },
    mobileMarginBottom: {
      type: String,
      required: false,
      default: 'mb-0',
    },
    mobileMarginTop: {
      type: String,
      required: false,
      default: 'mt-0',
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    desktopMarginBottom: {
      type: String,
      required: false,
      default: 'lg:mb-0',
    },
    paddingHorizontal: {
      type: String,
      required: false,
      default: 'px-2',
    },
    desktopPaddingHorizontal: {
      type: String,
      required: false,
      default: 'lg:px-4',
    },
    paddingVertical: {
      type: String,
      required: false,
      default: 'py-2',
    },
    desktopPaddingVertical: {
      type: String,
      required: false,
      default: 'lg:py-4',
    },
    desktopMarginTop: {
      type: String,
      required: false,
      default: 'lg:mt-0',
    },
    content: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxWidthMobile: {
      type: String,
      required: false,
      default: '',
    },
    maxWidthDesktop: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ifSwipeableClass: ({ mobileSwipeable }) =>
      mobileSwipeable ? 'w-[90vw] min-w-[90vw] lg:w-auto lg:min-w-0' : '',
    backgroundColorCss() {
      return color(this.backgroundColor, this.backgroundColorHex)
    },
  },
}
</script>
