var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.hideMobile ? 'hidden' : '',
    _vm.hideDesktop ? 'lg:hidden' : 'lg:block',
  ]},[_c('div',{class:[
      _vm.mobileGap,
      _vm.rowGapMobile,
      _vm.rowGapDesktop,
      _vm.mobileMarginBottom,
      _vm.desktopMarginBottom,
      _vm.mobileMarginTop,
      _vm.desktopMarginTop,
      _vm.desktopGap,
      _vm.desktopColumns,
      _vm.mobileColumns,
      _vm.paddingHorizontal,
      _vm.desktopPaddingHorizontal,
      _vm.paddingVertical,
      _vm.desktopPaddingVertical,
      _vm.maxWidthMobile,
      _vm.maxWidthDesktop,
      _vm.mobileSwipeable
        ? 'overflow-x-scroll wrapper flex relative max-w-full lg:grid scrollbar-hide'
        : 'grid justify-center',
    ],style:(`background-color: ${_vm.backgroundColorCss}`)},_vm._l((_vm.content),function(item){return _c(item.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],key:item.id,tag:"component",class:[_vm.layoutMobile, _vm.layoutDesktop, _vm.ifSwipeableClass],attrs:{"full-width":_vm.layoutDesktop.includes('lg:col-start-1 lg:col-end-13')}},'component',{ ...item },false))}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }